const Body = document.querySelector('body')

/**
 * Toggle Body Overlay
 *
 * @returns {void}
 */
export const toggleBodyOverlay = (forcedValue = null) => Body.classList.toggle('no-scroll', forcedValue)

export const zeroPad = (num, places = 2) => String(num).padStart(places, '0')

/**
 * Normalize Time
 * @param {int} seconds
 * @returns {string} mm:ss
 */
export const normalizeTime = (seconds) => {
  let roundedSeconds = Math.round(seconds)

  let time = {
    mm: zeroPad(Math.floor(roundedSeconds / 60)),
    ss: zeroPad(roundedSeconds % 60),
  }

  return `${time.mm}:${time.ss}`
}

/**
 * Get Formatted Amount
 */
const toCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const getAmount = (amount = 0) => {
  return toCurrency.format(amount / 100)
}

/**
 * Bulk addEventListener
 *
 * @param {Node} list
 * @param {Event} event
 * @param {Function} fn Function
 *
 * @returns {void}
 */
export const addEventListenerList = (list, event, fn) => {
  for (var i = 0, len = list.length; i < len; i++) {
    list[i].addEventListener(event, fn, false)
  }
}

/**
 * Slug generator
 */
export const toSlug = (text) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

/**
 * Debounce
 */
export const debounce = (func, wait) => {
  let timeout
  return function executedFunction(...args) {
    const later = () => {
      timeout = null
      func(...args)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

/**
 * Button: loading state
 */
export const toggleLoadingButton = (Button, state) => {
  Button.toggleAttribute('disabled', state)
  Button.classList.toggle('btn--loading', state)
}
